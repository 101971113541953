.App {
  text-align: center;
}
h3 {
  font-size: 24.5px;
  line-height: 40px;
  margin: 10px 0;
  font-weight: bold;
  color: inherit;
  text-rendering: optimizelegibility;
}
body {
  font-size: 13px;
  font-family: 'Roboto', helvetica, sans-serif;
}
a {
  text-decoration: none;
  color: rgb(223, 113, 0);
}
.btn-primary {
  background-color: rgb(24, 77, 112);
  background-image: linear-gradient(rgb(43, 106, 148), rgb(24, 77, 112));
  background-position-x: 0%;
  background-position-y: -2.4999px;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-outset: 0px;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgba(0, 0, 0, 0.1);
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: rgba(0, 0, 0, 0.1);
  border-right-style: solid;
  border-right-width: 1px;
  border-top-color: rgba(0, 0, 0, 0.1);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top-style: solid;
  border-top-width: 1px;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 1px 0px 0px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: block;
  filter: none;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  margin-bottom: 0px;
  outline-color: rgb(255, 255, 255);
  outline-style: none;
  outline-width: 0px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 4px;
  text-align: center;
  text-decoration-color: rgb(255, 255, 255);
  text-decoration-line: none;
  text-decoration-style: solid;
  text-shadow: rgba(0, 0, 0, 0.25) 0px -1px 0px;
  text-size-adjust: 100%;
  touch-action: manipulation;
  transition-delay: 0s;
  transition-duration: 0.1s;
  transition-property: background-position;
  transition-timing-function: linear;
  vertical-align: middle;
  width: 99.84px;
  will-change: color, height, width, background-color, font-size, opacity;
  -webkit-box-direction: normal;
}
.object__image {
  width: 100%;
}
.object__wrapper {
  border: 1px solid #ddd;
  margin-bottom: 2rem;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  color: rgb(85, 85, 85);
  background-color: #fff;
}
.object__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.object__list_wrapper {
  display: flex;
  width: 100%;
  margin: 1rem 0px 2rem 0px;
  line-height: 1.4rem;
}
.object__list_container {
  flex: 1;
  padding: 0px 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.object__list_title {
  font-weight: 600;
  margin-bottom: .5rem;
  text-align: center;
  font-size: 1rem;
}
.object__list_title.left {
  text-align: left;
}
.object__features_row {
  border-bottom: 1px solid #ddd;
  padding: 3px 0px;
  text-align: left;
}
.object__specifications_row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 3px 0px;
}
.object__body {
  font-size: 1rem;
  margin-bottom: 1rem;
}
.object__stars_wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 3px;
  height: 12px;
}
.object__stars_container {
  position: relative;
  width: 68px;
}
.object__stars_gray {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNHB4IiBoZWlnaHQ9IjEycHgiIHZpZXdCb3g9IjAgMCAyMSAxOCIgZmlsbD0iI2Q2ZDZkNiI+PHBhdGggZD0iTTksMTQgMTUsMTggMTIsMTEgMTgsN0gxMUw5LDAgNyw3SDBMNiwxMSAzLDE4eiIvPjwvc3ZnPgo=);
  height: 12px;
  width: 68px;
  position: absolute;
}
.object__stars_filled {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNHB4IiBoZWlnaHQ9IjEycHgiIHZpZXdCb3g9IjAgMCAyMSAxOCIgZmlsbD0iI2U3NzExYiI+PHBhdGggZD0iTTksMTQgMTUsMTggMTIsMTEgMTgsN0gxMUw5LDAgNyw3SDBMNiwxMSAzLDE4eiIvPjwvc3ZnPgo=);
  height: 12px;
  position: absolute;
}
.object__price_wrapper {
  display: flex;
  justify-content: center;
}
.object__price_title {
  font-weight: 600;
  margin-right: .5rem;
}
.object__price_price {
  font-weight: 600;
  color: green;
  letter-spacing: 1px;
}
@media only screen and (max-width: 768px) {
  .object__list_wrapper {
    flex-direction: column;
    margin: 1rem 0px;
  }
  .object__list_container {
    margin-bottom: 1rem;
  }
  .object__list_title.left {
    text-align: center;
  }
}


/* ----------------------- */

.object__wrapper {
  font-size: 13px;
}
.object_navigation > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}
.object_navigation li {
  width: 50%;
  line-height: 2rem !important;
  list-style-type: none;
  font-size: 1rem;
}
.object_navigation .content {
  overflow-y: hidden;
  height: 0;
  transition: height 0.3s ease;
}
.object_navigation ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  margin-top: 0px;
  padding: 1rem 2rem;
  margin-left: 0px;
}
.object_navigation > input[type="checkbox"]:checked ~ .content {
  height: auto;
  overflow: visible;
}
.object_navigation__title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  font-size: 24.5px;
  line-height: 40px;
  font-weight: 300;
  background-color: #000;
  padding: 15px;
  color: #eee;
}
.object_navigation label:hover,
.object_navigation label:focus {
  color: #ccc;
  cursor: pointer;
}
.object_navigation {
  margin-bottom: 2rem;
}
.object_navigation > input[type="checkbox"]:checked ~ .content {
  border-top: 0;
}
.object_navigation .object_navigation__handle {
  margin: 0;
  float: right;
  font-size: 1rem;
  cursor: pointer;
}
